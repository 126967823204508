import React from 'react';

function LoadingScreen() {
  return (
    <div className='flex items-center justify-center bg-radiography-gray h-screen'>
         <div className='w-32 h-32'>
            <img className='w-full h-full object-cover' src="/images/Prechs_Loader.gif" alt="Loader" />
         </div>
    </div>
  );
}

export default LoadingScreen;

