import React from "react";
import { HiOutlineX } from "react-icons/hi";


function NotificationModal({ setColse, title, prompt, onNotify }) {

    return (
        <div className="h-screen w-screen fixed inset-0 z-50  flex justify-center items-center px-2 bg-black bg-opacity-95">
            <div className="flex flex-col space-y-4 bg-white rounded-2xl shadow-lg w-96 md:w-[500px]">
                <div className="flex items-center justify-between border-b px-4 py-2">
                    <h3 className="text-gray-600 font-semibold"> {title} </h3>
                    <HiOutlineX
                        className="h-6 w-6 cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            setColse();
                        }}
                    />
                </div>

                {/* Modal body */}
                <div className="flex flex-col space-y-2 items-center justify-center">

                    <div className="flex items-center justify-center px-4 py-2">
                        <p>{prompt}</p>
                    </div>


                    <div className="flex items-center justify-between  w-full px-5 lg:px-10 py-3">
                        <button onClick={(e) => {
                            e.preventDefault();
                            setColse();
                        }} className="inline-flex items-center justify-center rounded-full  transition duration-500 ease-in-out text-gray-500 py-2 px-3 bg-gray-200 hover:bg-gray-300">
                            Cancel
                        </button>

                        <button onClick={onNotify} className="inline-flex items-center justify-center rounded-full  transition duration-500 ease-in-out text-gray-500 py-2 px-6 bg-gray-200 hover:bg-gray-300">
                            ok
                        </button>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default NotificationModal;
